import slugify from "slugify";

/**
 * @see https://docs.directus.io/reference/files/#custom-transformations
 */
interface AssetTransformations {
    fit: "cover" | "contain" | "inside" | "outside";
    width: number;
    height: number;
    quality: number;
    withoutEnlargement: boolean;
    format: "jpg" | "png" | "webp" | "tiff";
}

export interface SportCluster {
    root: string;
    previews: `${string}/previews`;
    preview: (slug: string) => `${string}/previews/${string}`;
}

export class LinkTo {
    constructor(private apiUrl: string) {}

    public asset(
        assetId: string,
        options?: Partial<{seoFriendlyName: string} & AssetTransformations>
    ): string {
        const {seoFriendlyName, format = "webp", quality = 80, ...transformations} = options || {};

        const baseAssetUrl = `${this.apiUrl}/assets/${assetId}`;
        const optionalSuffix = seoFriendlyName ? `/${seoFriendlyName}` : "";

        // @ts-ignore
        const searchParameters = new URLSearchParams({
            format,
            quality,
            ...transformations
        }).toString();

        return `${baseAssetUrl}${optionalSuffix}?${searchParameters}`;
    }

    public static fullPageUrl(): string {
        return "https://bettorsignals.com";
    }

    public static homePage(): string {
        return "/";
    }

    public static signalPage(id: string, title: string): string {
        return `/signal/${id}/${slugify(title, {lower: true, strict: true})}`;
    }

    public static nfl(): SportCluster {
        return {
            root: "/nfl",
            previews: "/nfl/previews",
            preview: (slug: string) => `/nfl/previews/${slug}`
        };
    }

    public static previewsPage(): string {
        return "/previews";
    }

    public static bookmakersPage(): string {
        return "/bookmakers";
    }

    public static bonusesPage(): string {
        return "/bonuses";
    }

    public static aboutUsPage(): string {
        return "/about-us";
    }

    public static blogPage(): string {
        return "/blog";
    }

    public static blogPostPage(slug: string): string {
        return `/blog/${slug}`;
    }

    public static privacyPolicyPage(): string {
        return "/privacy-policy";
    }

    public static termsAndConditionsPage(): string {
        return "/terms-and-conditions";
    }

    public static disclaimerPage(): string {
        return "/disclaimer";
    }

    public static twitterPage(): string {
        return "https://twitter.com/BettorSignals";
    }

    public static instagramPage(): string {
        return "https://www.instagram.com/bettorsignals";
    }

    public static telegramChannel(): string {
        return "https://t.me/bettorsignalscom";
    }

    public static facebookGroup(): string {
        return "https://www.facebook.com/groups/414786127361201";
    }

    public static offer(id: string): string {
        return `/go/offer/${id}`;
    }

    public static bookmaker(id: string): string {
        return `/go/bookmaker/${id}`;
    }
}
